import type { H3Event } from "h3";

export function clientFileDownload(value: Blob | string, fileName: string) {
  let url: string;
  if (isBlob(value)) {
    url = URL.createObjectURL(value);
  } else {
    url = URL.createObjectURL(new Blob([value]));
  }
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
}

export async function serverFileDownload(
  event: H3Event,
  result: Blob | string,
): Promise<Blob | string> {
  if (isBlob(result)) {
    return (await send(
      event,
      Buffer.from(await result.arrayBuffer()),
    )) as unknown as Blob;
  } else {
    return result;
  }
}

export function isBlob(value: unknown): value is Blob {
  const blob = value as Blob;
  return (
    blob !== undefined &&
    blob !== null &&
    blob.size != undefined &&
    blob.arrayBuffer != undefined &&
    blob.stream != undefined
  );
}
